import React,{ useState } from 'react';
import './Home.css';
import './Tabs.css';
  function WorkExperience() {
    const [activeJobs,setActiveJobs] = useState(["active","","",""]);
    
    const onClickHandler=(i)=>{
        switch(i){
            case 0:
                setActiveJobs(["active","","",""]);
                break;
            case 1:
                setActiveJobs(["","active","",""]);
                break;
            case 2:
                setActiveJobs(["","","active",""]);
                break;
            case 3:
                setActiveJobs(["","","","active"]);
                break;
            default:
                break;
        }
        
    }
    // let tabPanes = _class("tab-header")[0].getElementsByTagName("div");
    // for(let i=0;i<tabPanes.length;i++){
    // tabPanes[i].addEventListener("click",function(){
    //   _class("tab-header")[0].getElementsByClassName("active")[0].classList.remove("active");
    //   tabPanes[i].classList.add("active");
    //   _class("tab-indicator")[0].style.top = `calc(80px + ${i*50}px)`;
    //   _class("tab-content")[0].getElementsByClassName("active")[0].classList.remove("active");
    //   _class("tab-content")[0].getElementsByTagName("div")[i].classList.add("active");
      
    // });
    return (
        <div className="workexperience" id="workexp">
            <div className="title">
                Where I've Worked
            </div>

            <div class="tabs">
            <div class="tab-header" >
                <div class={activeJobs[0]} onClick={()=>onClickHandler(0)} >
                EC Infosystems
                </div>
                <div class={activeJobs[1]} onClick={()=>onClickHandler(1)}>
                A / V  Services
                </div>
                <div class={activeJobs[2]} onClick={()=>onClickHandler(2)} >
                ID Tech Academy
                </div>
                <div class={activeJobs[3]} onClick={()=>onClickHandler(3)}>
                Alphaserve Technologies
                </div>
            </div>
            <div class="tab-content">
                <div class={activeJobs[0]}>
                <p class="workTitle">EC Infosystems</p>
                <p class="content-header">IT Intern (Mar 2020 - Oct 2020)</p>
                <ul>
                    <li>Developed systems to gather and store internal asset information into a database using Powershell, SQL, MS SQL </li>
                    <li>Utilized ASP.NET Razor Pages to develop a web application to manage over 600 internal devices </li>
                    <li>Implemented and planned IT Change Control policies to properly log/maintain information </li>
                </ul>
                </div>
                
                <div class={activeJobs[1]}>
                <p class="workTitle">A / V Services @ Stony Brook University</p>
                <p class="content-header">AV Support Staff (Nov 2017 - Sept 2019)</p>
                <ul>
                    <li>Trained users in usage of various audio/visual software and hardware on a daily basis </li>
                    <li>Conducted customer service calls in assisting faculty as well as students in technical issues </li>
                    <li>Performed troubleshooting, maintenance and organization of internal equipment</li>
                </ul>
                </div>
                
                <div class={activeJobs[2]}>
                <p class="workTitle">ID Tech Academy @ Columbia University</p>
                <p class="content-header">Instructor (Jul 2018 - Aug 2018)</p>
                <ul>
                    <li>Taught 10 junior engineers to develop various types of Java and Python projects each week</li>
                    <li>Tailored workflows and developed curriculums for each individual to achieve maximum productivity </li>
                    <li>Assisted students in debugging incomplete projects to completion</li>
                </ul>
                </div>
                
                <div class={activeJobs[3]}>
                <p class="workTitle">Alphaserve Technologies</p>
                <p class="content-header">Technical Coordination Intern (Jun 2016 - Aug 2016)</p>
                <ul>
                    <li>Managed, redirected, and assigned internal and cliental tickets to appropriate departments</li>
                    <li>Utilized Excel to reorganize and reconcile sensitive client data into a readable format</li>
                </ul>
                </div>
    
            </div>
            </div>
        </div>
    )
}

export default WorkExperience
