import React from 'react'
import './Home.css';
import './Skills.css';
import ProfilePic from '../files/ProfilePic.jpg'

function Home() {
    return (
        <div className="home" id="homeEle">
            <div className="title">
                About Me
            </div>
            <div className="aboutme">
                <p> <br/>
                    Hi! My name is Pratyush Vyas. I'm a Software Developer based in New York. I'm a recent graduate from Stony Brook University with a B.S. in Computer Science,
                    currently pursuing opportunities in the Software Development field. 
                    When I'm not programming, I enjoy playing video games, going on trips, and learning new skills.         
                    Feel free to reach out! 
                    <br/>
                </p>
                <img className="img-border" alt="PratyushVyasImg" src={ProfilePic}></img>
            </div>

            <div className="skills-title">
                Here are some of the technologies I've worked with
            </div>
            
            <div className="skills">
                <ul className="skills-list">
                    <p className="subskill-title">{ 'Programming Languages '}</p> 
                    <li className="list-item"> Java </li>
                    <li className="list-item"> C </li>
                    <li className="list-item"> Python </li>
                    <li className="list-item"> JavaScript</li>
                    <li className="list-item"> C++ </li>
                </ul>
                <ul className="skills-list">
                    <p className="subskill-title">{ 'Web Development '}</p> 
                    <li className="list-item"> React </li>
                    <li className="list-item"> Express </li>
                    <li className="list-item"> Mongoose </li>
                    <li className="list-item"> Node.js </li>
                    <li className="list-item"> CSS </li>
                    <li className="list-item"> HTML </li>
                </ul>
            </div>
        </div>
    )
}

export default Home
