
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router} from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects';
import WorkExperience from './components/WorkExperience';
import ParticleBackground from './components/ParticleBackground';
import Resume from './components/Resume';
import Footer from './components/Footer';
import ResumeDLButton from './components/ResumeDLButton';
function App() {
  return (
    <div className="App">
        <Router>
        <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}>

        <Navbar/>
        
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%"
          }}
          >
        <Home/>
        <WorkExperience/>
        <Projects/>
        <Resume/>

        <Footer/>
        </div>
        <ParticleBackground val="particles-js"/>
        <ParticleBackground val="particles-js-two"/>
        <ParticleBackground val="particles-js-three"/>
        <ParticleBackground val="particles-js-four"/>
        </div>
      </Router>
    </div>
  );
}

export default App;
