import React from 'react'
import './Footer.css';
import { Button } from '@material-ui/core';
import * as clipboard from "clipboard-polyfill/text";
function Footer() {

    function copyToClipBoard(){
        clipboard.writeText("hiimprat@gmail.com").then(
            function(){
                alert("Link has been copied to the clipboard");
            },function(){
                console.log("error");
            }
        );
    }
    return (
        <div className="footer" id ="contact">
            Feel free to contact me!
            <div className="info">
            
            <div className="email"><a href="mailto:pvyas.dev@gmail.com" className="emailButton">pvyas.dev@gmail.com</a>
            </div>
            <div className="github">
            <a className="githubLink" href="https://github.com/hiimprat"><i class="fab fa-github"/></a>
            </div>
            <a className="githubLink" href="https://linkedin.com/in/pratyush-vyas"><i class="fab fa-linkedin-in"></i></a>
            </div>
        </div>
    )
}

export default Footer
