import React from 'react'
import './Home.css';
import KnightShortestPath from '../files/KnightShortestPath.PNG';
import CassetteNet from '../files/CassetteNet.JPG';
import './Projects.css';
import Carousel from 'react-elastic-carousel'
function Projects() {
   
    return (
        
        <div className="projects" id="projects">
            <div className="title">
                Here's some stuff I've worked on
            </div>
            <Carousel  itemsToShow={1}>
            <div className="cards">
              <div className="cardTitle">CassetteNet</div>
              <div className="cardContent">
                <img src={CassetteNet} className="CassetteNetImg"></img>
                <div className="cardDetails">
                <p>CassetteNet is a web application that allows its users to create a
                playlist of songs via queries through the SoundCloud and Youtube APIs.</p>
                <p>Users are also capable of sharing, favoriting and collaborating with other users 
                  to build a playlist together.
                </p>
                <ul>
                  <li className="tags">Express</li>
                  <li style={{whiteSpace: 'nowrap'}} className="tags">Material-UI</li>
                  <li className="tags">Axios</li>
                  <li className="tags">MongoDB</li>
                  <li className="tags">React </li>
                </ul>
                <a className="githubLink" href="https://github.com/hiimprat/CassetteNet"><i class="fab fa-github"/></a>
              </div>
              </div>
            </div>

            <div className="cards">
              <div className="cardTitle">Knight's Shortest Path (In Progress)</div>
              <div className="knightContent">
              <img src={KnightShortestPath} className="KnightImg"></img>
              <div className="cardDetails">
                <p>Knight's Shortest Path is the implementation of using breadth-first search to find a Knight's shortest path to a user-specified 
                  destination. The user can specify the knight's starting and ending points and the algorithm will "visit" every possible position until the
                  knight "reaches" its destination.
                </p>
                <ul>
                  <li className="tags">React </li>
                  <li style={{whiteSpace: 'nowrap'}} className="tags">Material-UI</li>
                  <li className="tags">CSS</li>
                  <li className="tags">Javascript</li>
                </ul>
              <a className="githubLink" href="https://github.com/hiimprat/KnightShortestPath"><i class="fab fa-github"/></a>
              </div>
              </div>
            </div>
            </Carousel>
            
        </div>
    )
}

export default Projects
