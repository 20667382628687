import React from 'react'
import './ParticleBackground.css'
import Particles from 'react-particles-js';
function ParticleBackground(props) {
    const {val} = props;

    return (
        <div>
            <Particles
                id={val}
                params={{ 
                    "particles": { 
                        "number": { 
                            "value": 30,
                            "density":{
                                "enable":true,
                                "value_area":100,
                            }
                        },
                        "line_linked": {
                            "enable": false,
                          },
                        "move":{
                            "enable":true,
                            "speed":1,
                        },
                        
                    }, 
                    }
            } 
          /> 
          
        </div>
    )
}

export default ParticleBackground
