import React from 'react'
import './Home.css';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import resumePDF from '../files/Pratyush_Vyas_Resume.pdf';
import { useEffect } from 'react';
import SaveAltIcon from '@material-ui/icons/SaveAlt';


function Resume() {

    useEffect(() => {
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [])
    return (
      <div className="resume" id="resume">
        <Document className="pdf" file={resumePDF}>
          <Page pageNumber={1} />
        </Document>

        
        <Button className="resumeDLButton" startIcon={<SaveAltIcon style={{fill:"white"}} />} variant="contained" style={{backgroundColor:"black", marginBottom:'5vh'}}><a href={resumePDF} download>
         <span style={{color:"white", fontFamily:"monospace"}}>Download Resume </span></a></Button>
      
      </div>

    )
}

export default Resume
