import React, { useState } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import './Navbar.css'


function Navbar() {
    const [clicked, setClicked] = useState(false);
    //const [buttonTrue,setButtonTrue] = useState(true);

    const handleClick = ()=>{
        setClicked(!clicked);
    }
    const closeMobileMenu = () => setClicked(false);
    const scrollToTop = () => {
        scroll.scrollToTop();
    };
    /*const showButton =()=>{
        if(window.innerWidth<=960){
            setButtonTrue(false);
        }
        else{
            setButtonTrue(true);
        }
    };
    window.addEventListener('resize',showButton);
    //<i class="fas fa-chess-king"/>
    */
    return (
        <div>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to= "/" onClick={scrollToTop} className="navbar-logo">
                    Pratyush Vyas
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={clicked ? 'fas fa-times': 'fas fa-bars'}/>
                    </div>
                    <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                        <Link
                                activeClass="active"
                                to="homeEle"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className='nav-links' onClick={closeMobileMenu}
                                style={{cursor:'pointer'}}
                            >
                            About Me
                        </Link>
                        </li>
                        <li className='nav-item'>
                        <Link
                            activeClass="active"
                            to="workexp"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={{cursor:'pointer'}}
                            className='nav-links' onClick={closeMobileMenu}>
                            Experience
                        </Link>
                        </li>
                        <li className='nav-item'>
                        <Link
                            activeClass="active"
                            to="projects"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={{cursor:'pointer'}}
                            className='nav-links' onClick={closeMobileMenu}>
                            Projects
                        </Link>
                        </li>
                        <li className='nav-item'>
                        <Link
                            activeClass="active"
                            to="resume"
                            spy={true}
                            smooth={true}
                            offset={-60}
                            duration={500}
                            style={{cursor:'pointer'}}
                            className='nav-links' onClick={closeMobileMenu}>
                            Resume
                        </Link>
                        </li>
                        <li className='nav-item'>
                        <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-60}
                            duration={500}
                            style={{cursor:'pointer'}}
                            className='nav-links' onClick={closeMobileMenu}>
                            Contact Me
                        </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;
